@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");
#root,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
body {
  font-family: "Sen", sans-serif !important;
}

.dashboard.pl-0 {
  padding-left: 0;
}

p {
  color: #677788;
}

.menus {
  background: #fff;
  position: sticky;
  z-index: 99;
  width: 100%;
  top: 0;
  transition: all ease-in-out 0.3s;
}
.menus a {
  cursor: pointer;
  text-decoration: none !important;
}
.menus.active {
  background: #fff;
  box-shadow: 0 2px 5px rgba(18, 25, 97, 0.09);
}
.menus .active,
.menus :hover {
  color: #18ce0f;
}
.menus.add-to-scroll-down {
  transform: translateY(-100%);
}
.menus header {
  height: 70px;
  max-height: 70px;
  background: transparent;
  border: none;
}
.menus header .mantine-16nnyi7:hover {
  background-color: transparent;
}
.menus + .footer {
  padding-top: calc(100vh - 100px);
  border: none;
  background-color: #fff;
}

.home {
  padding: 5rem 0;
}
.home .content .built-for {
  display: flex;
  justify-content: start;
  align-items: center;
  border-top: 1px solid #eee;
  margin-top: 2rem;
  padding-top: 1.5rem;
  max-width: 370px;
}
.home .content .built-for img {
  max-height: 30px;
}
.home .content .built-for .mantine-Image-root {
  width: unset !important;
  margin-left: 1.2rem;
}
.home h1 {
  font-weight: 900;
  margin-top: 0;
  font-size: 50px;
}
.home p {
  color: #677788;
  font-size: 18px;
  font-weight: 500;
  max-width: 510px;
  margin-bottom: 30px;
}
.home .hero-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
}

.app-screen, .facts img {
  position: relative;
}
.app-screen img, .facts img img {
  width: 700px !important;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  background-color: #e9eff4;
  padding: 1.1rem;
  z-index: 2;
  margin-left: 60px;
}

.features {
  background-color: #f7fafe;
}
.features ul {
  list-style: none;
  margin-left: 25px;
}
.features ul small {
  color: #40bf56;
  cursor: pointer;
  display: inline-flex;
}
.features li {
  position: relative;
  padding: 2.5px 0;
  transition: height 0.2s;
}
.features li ::before {
  content: "✤";
  position: absolute;
  left: -25px;
}
.features .mantine-Image-root {
  box-shadow: 0 2.75rem 3.5rem 1.5rem rgba(189, 197, 209, 0.2), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.2);
}

.facts {
  background-color: #fff;
}
.facts img {
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 1.5rem -2rem rgba(33, 50, 91, 0.01), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  width: 100% !important;
  margin: 0 !important;
}
.facts .item {
  box-shadow: 0 2px 5px rgba(18, 25, 97, 0.05);
}

.pricing-list {
  background-color: #fff;
}
.pricing-list .select-plan {
  max-width: 700px;
  display: flex;
  border: 1px solid #e3e6ef;
  padding: 16px 20px;
  font-weight: 600;
  justify-content: center;
  border-left: none;
  border-right: none;
  margin: 0 auto 7rem;
  line-height: 1;
}
.pricing-list .select-plan .mantine-Radio-root {
  margin-right: 7px;
  margin-left: 7px;
}
.pricing-list .select-plan span {
  color: #677788;
  opacity: 0.7;
  font-weight: 400;
}
.pricing-list .select-plan > div {
  padding-top: 0;
}
.pricing-list .item {
  padding: 40px;
  z-index: 2;
  max-width: 430px;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3);
}
.pricing-list .item.extend {
  margin-left: -1px;
  z-index: 1;
  border-radius: 0 32px 32px 0;
  background: transparent;
  box-shadow: none;
  max-width: 390px;
}
.pricing-list .item button {
  width: 100%;
}
.pricing-list .item .mantine-List-root {
  list-style: none;
  margin-left: 35px;
  padding: 15px 0;
}
.pricing-list .item h1 {
  font-size: 60px;
  padding: 0.1rem 0 0.5rem;
}
.pricing-list .item h1 sub {
  font-size: 35%;
  bottom: 3px;
  color: #aaa;
}
.pricing-list .item .mantine-List-item {
  white-space: unset !important;
  font-size: 15px;
  padding: 5px 0;
}
.pricing-list .item .mantine-List-item svg {
  float: left;
  margin-left: -35px;
  margin-top: 3px;
  stroke: #5c7cf9;
}
.pricing-list .item .mantine-List-item .mantine-List-itemWrapper {
  display: inline;
}

.blog-page {
  background: #fff;
}
.blog-page img {
  border-radius: 8px;
}
.blog-page .post-item {
  height: 100%;
}
.blog-page .more-link {
  display: none;
}
.blog-page h4 {
  padding-top: 10px;
}
.blog-page h4 a {
  font-size: 22px;
  line-height: 34px;
  text-decoration: none;
  color: #000 !important;
}

.blog-post {
  background-color: #fff;
}
.blog-post h1,
.blog-post .post-details {
  max-width: 700px;
  text-align: left;
}
.blog-post h1 {
  font-weight: 800;
  color: #000;
  line-height: 50px;
  margin: 40px 0;
}
.blog-post img {
  border-radius: 10px;
  width: 100%;
}
.blog-post .post-details {
  padding: 30px 0;
}
.blog-post .mantine-Divider-root div {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.modal .mantine-Modal-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.modal .mantine-Modal-content {
  padding: 1rem;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=shopexLanding.css.map */