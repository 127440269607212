@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");

$body-color: #677788;
$primary-color: #18ce0f;

#root,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
body {
  font-family: "Sen", sans-serif !important;
}

.dashboard.pl-0 {
  padding-left: 0;
}

p {
  color: #677788;
}
.menus {
  background: #fff;
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;
  width: 100%;
  top: 0;
  transition: all ease-in-out 0.3s;

  a {
    cursor: pointer;
    text-decoration: none !important;
  }

  &.active {
    background: #fff;
    box-shadow: 0 2px 5px rgb(18 25 97 / 9%);
  }

  .active,
  :hover {
    color: $primary-color;
  }
  &.add-to-scroll-down {
    transform: translateY(-100%);
  }

  header {
    height: 70px;
    max-height: 70px;
    background: transparent;
    border: none;

    .mantine-16nnyi7:hover {
      background-color: transparent;
    }
  }
  & + .footer {
    padding-top: calc(100vh - 100px);
    border: none;
    background-color: #fff;
  }
}
.home {
  padding: 5rem 0;

  .content {
    .built-for {
      display: flex;
      justify-content: start;
      align-items: center;
      border-top: 1px solid #eee;
      margin-top: 2rem;
      padding-top: 1.5rem;
      max-width: 370px;
      img {
        max-height: 30px;
      }
      .mantine-Image-root {
        width: unset !important;
        margin-left: 1.2rem;
      }
    }
  }
  h1 {
    font-weight: 900;
    margin-top: 0;
    font-size: 50px;
  }
  p {
    color: $body-color;
    font-size: 18px;
    font-weight: 500;
    max-width: 510px;
    margin-bottom: 30px;
  }

  .hero-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
  }
}
.app-screen {
  position: relative;

  img {
    width: 700px !important;
    box-shadow: 0 2.75rem 5.5rem -3.5rem rgb(189 197 209 / 50%),
      0 2rem 4rem -2rem rgb(33 50 91 / 30%),
      inset 0 -0.1875rem 0.3125rem 0 rgb(151 164 175 / 50%);
    background-color: #e9eff4;
    padding: 1.1rem;
    z-index: 2;
    margin-left: 60px;
  }
}
.features {
  background-color: #f7fafe;

  ul {
    list-style: none;
    margin-left: 25px;

    small {
      color: #40bf56;
      cursor: pointer;
      display: inline-flex;
    }
  }

  li {
    position: relative;
    padding: 2.5px 0;
    transition: height 0.2s;

    ::before {
      content: "\2724";
      position: absolute;
      left: -25px;
    }
  }
  .mantine-Image-root {
    box-shadow: 0 2.75rem 3.5rem 1.5rem rgb(189 197 209 / 20%),
      0 2rem 4rem -2rem rgb(33 50 91 / 20%);
  }
}
.facts {
  background-color: #fff;

  img {
    @extend .app-screen;
    box-shadow: 0 2.75rem 5.5rem -3.5rem rgb(189 197 209 / 50%),
      0 2rem 1.5rem -2rem rgb(33 50 91 / 1%),
      inset 0 -0.1875rem 0.3125rem 0 rgb(151 164 175 / 50%);
    width: 100% !important;
    margin: 0 !important;
  }

  .item {
    // border: none;
    box-shadow: 0 2px 5px rgba(18, 25, 97, 0.05);
  }
}
.pricing-list {
  background-color: #fff;

  .select-plan {
    max-width: 700px;
    display: flex;
    border: 1px solid #e3e6ef;
    padding: 16px 20px;
    font-weight: 600;
    justify-content: center;
    border-left: none;
    border-right: none;
    margin: 0 auto 7rem;
    line-height: 1;

    .mantine-Radio-root {
      margin-right: 7px;
      margin-left: 7px;
    }
    span {
      color: #677788;
      opacity: 0.7;
      font-weight: 400;
    }

    > div {
      padding-top: 0;
    }
  }
  .item {
    padding: 40px;
    z-index: 2;
    max-width: 430px;
    box-shadow: 0 2.75rem 5.5rem -3.5rem rgb(189 197 209 / 50%),
      0 2rem 4rem -2rem rgb(33 50 91 / 30%);

    &.extend {
      margin-left: -1px;
      z-index: 1;
      border-radius: 0 32px 32px 0;
      background: transparent;
      box-shadow: none;
      max-width: 390px;
    }

    button {
      width: 100%;
    }

    .mantine-List-root {
      list-style: none;
      margin-left: 35px;
      padding: 15px 0;
    }
    h1 {
      font-size: 60px;
      padding: 0.1rem 0 0.5rem;
      sub {
        font-size: 35%;
        bottom: 3px;
        color: #aaa;
      }
    }
    .mantine-List-item {
      white-space: unset !important;
      font-size: 15px;
      padding: 5px 0;

      svg {
        float: left;
        margin-left: -35px;
        margin-top: 3px;
        stroke: #5c7cf9;
      }

      .mantine-List-itemWrapper {
        display: inline;
      }
    }
  }
}

.blog-page {
  background: #fff;
  img {
    border-radius: 8px;
  }
  .post-item {
    height: 100%;
  }
  .more-link {
    display: none;
  }
  h4 {
    padding-top: 10px;

    a {
      font-size: 22px;
      line-height: 34px;
      text-decoration: none;
      color: #000 !important;
    }
  }
}
.blog-post {
  background-color: #fff;
  h1,
  .post-details {
    max-width: 700px;
    text-align: left;
  }
  h1 {
    font-weight: 800;
    color: #000;
    line-height: 50px;
    margin: 40px 0;
  }
  img {
    border-radius: 10px;
    width: 100%;
  }
  .post-details {
    padding: 30px 0;
  }
  .mantine-Divider-root div {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
}

.modal {
  .mantine-Modal-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .mantine-Modal-content {
    padding: 1rem;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
